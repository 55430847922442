import React from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import { RouteComponentProps } from 'react-router';
import getFormattedCurrencyString from '../../utils/getFormattedCurrencyString';
import getCardType from '../../utils/getCardType';
import HQApi from '../../api/HQApi';
import { PaymentMethodType } from '../Adyen/PaymentMethodSelector/Constants';
import { CreditCard, PaymentLink, PayRequest, PayResponse } from '../Models/Models';
import PaymentMethodSelector from '../Adyen/PaymentMethodSelector/PaymentMethodSelector';
import PayPalButtons from '../Adyen/PayPalButtons/PayPalButtons';
import './PaymentLinkDetails.scss';

interface IState {
    checkout: AdyenCheckout | undefined;
    selectedPaymentMethodType: PaymentMethodType | undefined;
    card: CreditCard | undefined;
    cardBrand: string | undefined;
    cardSurchargeCalculating: boolean;
    cardSurchargeCalculated: boolean;
    cardSurchargeValue: number | undefined;
    cardSurchargeCalculationErrorMessage: string | undefined;
    isProcessing: boolean;
}

interface IProps extends RouteComponentProps {
    paymentLink: PaymentLink;
    adyenComponentConfiguration: any;
    onPayment(paymentAmount: number, paymentResponse?: PayResponse): void;
}

class PaymentLinkDetails extends React.Component<IProps, IState>{

    state: IState = {
        checkout: undefined,
        selectedPaymentMethodType: undefined,
        card: undefined,
        cardBrand: undefined,
        cardSurchargeCalculating: false,
        cardSurchargeCalculated: false,
        cardSurchargeValue: undefined,
        cardSurchargeCalculationErrorMessage: undefined,
        isProcessing: false,
    };
    tempCardNumber: string | undefined;

    componentDidMount() {
        const checkout = new AdyenCheckout({
            locale: 'en_US',
            environment: this.props.adyenComponentConfiguration.Environment,
            originKey: process.env.REACT_APP_ORIGIN_KEY,
            paymentMethodsResponse: this.props.adyenComponentConfiguration.PaymentMethodsResponse,
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true, 
                    holderNameRequired: true, 
                    showPayButton: false, 
                    onChange: this.onCardChange,
                    onBrand: this.onCardBrandChanged
                },
                paypal: {
                    countryCode: this.props.paymentLink.divisionCountryCode,
                    amount: { 
                         currency: this.props.paymentLink.currencyCode, 
                         value: this.props.paymentLink.amount
                    }, 
                    onSubmit: this.onPayPalTransactionStarted,
                    onAdditionalDetails: this.makePayPalPayment
                }
            }
        });
        this.setState({
            checkout: checkout
        });
    }

    onPaymentMethodSelected = (type: PaymentMethodType) => {
        if (type === this.state.selectedPaymentMethodType) return;
        
        this.setState({ 
            selectedPaymentMethodType: type,
            card: undefined,
            cardBrand: undefined,
            cardSurchargeCalculated: false,
            cardSurchargeValue: undefined,
            cardSurchargeCalculationErrorMessage: undefined
        });
    }

    onCardBrandChanged = (state: { brand: string | null }) =>
        this.setState({
            cardBrand: !state.brand || !state.brand.length || state.brand === 'card'
                ? ''
                : getCardType(state.brand)
        });

    onCardChange = async (
        state: {
            data: {
                paymentMethod: { 
                    encryptedCardNumber: string;
                    encryptedExpiryMonth: string;
                    encryptedExpiryYear: string;
                    encryptedSecurityCode: string;
                    holderName: string; 
                };
            };
            isValid: boolean;
        }
    ) => {
        const card = state?.data?.paymentMethod;

        if (card && card.encryptedCardNumber !== this.tempCardNumber) {
            this.tempCardNumber = card.encryptedCardNumber;

            this.setState({
                cardSurchargeValue: undefined,
                cardSurchargeCalculationErrorMessage: undefined,
                cardSurchargeCalculated: false
            });
    
            if (!card.encryptedCardNumber) return;
    
            try {
                this.setState({
                    cardSurchargeCalculating: true
                });
    
                const response = await HQApi.post('api/v1/creditcards/calculatesurcharge', {
                     DivisionId: this.props.paymentLink.divisionId,
                     CreditCardNumber: card?.encryptedCardNumber,
                     Amount: this.props.paymentLink.amount
                });

                this.setState({
                    cardSurchargeValue: response.data.surcharge,
                    cardSurchargeCalculated: true
                });
            } catch (error) {
                this.setState({
                    cardSurchargeCalculationErrorMessage: 'Surcharge calculation failed, please reenter card details correctly or use a different card.'
                });
            } finally {
                this.setState({
                    cardSurchargeCalculating: false
                });
            }
        }

        this.setState({
            card: state.isValid
            ? {
                name: card.holderName,
                number: card.encryptedCardNumber,
                expiryMonth: card.encryptedExpiryMonth,
                expiryYear:  card.encryptedExpiryYear,
                securityCode: card.encryptedSecurityCode
            }
            : undefined
        });
    };

    private readonly onPayPalTransactionStarted = async (_: any, component: any) => {
        const { paymentLink, onPayment } = this.props;

        try { 
            const response = await HQApi.post('api/v1/payments/getpaypalconfiguration', {
                DivisionId: paymentLink.divisionId,
                BrandId: paymentLink.brandId,
                ReferenceNo: paymentLink.referenceNo,
                CustomerFirstName: paymentLink.customerFirstName,
                CustomerLastName: paymentLink.customerLastName,
                CustomerEmail: paymentLink.customerEmailAddress,
                Amount: paymentLink.amount + paymentLink.payPalSurcharge,
            }); 

            if (response.data.configuration)
                component.handleAction(JSON.parse(response.data.configuration));
        } catch (error: any) {
            onPayment(
                paymentLink.amount,
                {
                    isSuccess: false,
                    message: error,
                    transactionNumber: ''
                });
        }
    }

    private readonly makePayPalPayment = async (state: any, component: any) => { 
        const { paymentLink } = this.props;
        const { data } = state;

        return this.makePayment({
            id: paymentLink.id,
            amount: paymentLink.amount,
            surcharge: paymentLink.payPalSurcharge,
            payPalResult: {
                paymentData: data.paymentData,
                facilitatorAccessToken: data.details.facilitatorAccessToken, 
                orderId: data.details.orderID,
                payerId: data.details.payerID
            }
        });
    }

    private makeCardPayment = async () => {
        const { paymentLink } = this.props;
        const { cardSurchargeValue, card } = this.state;
        
        return this.makePayment({
            id: paymentLink.id,
            amount: paymentLink.amount,
            surcharge: (cardSurchargeValue || 0),
            creditCard: card
        });
    }

    private makePayment = async (payRequest: PayRequest) => {
        this.setState({
            isProcessing: true
        });

        let payResponse: PayResponse;
        try {
            const response = await HQApi.post('api/v2/paymentlinks/pay', payRequest);
            payResponse = response.data;
        } catch (error: any) {
            payResponse = {
                isSuccess: false,
                message: error,
                transactionNumber: ''
            };
        }

        this.setState({
            isProcessing: false
        },
        () => this.props.onPayment(
            payRequest.amount + payRequest.surcharge,
            payResponse));
    }

    private getFormatttedCurrency = (value: number) => getFormattedCurrencyString(
        value,
        this.props.paymentLink.divisionLocale,
        this.props.paymentLink.currencyCode);

    surchargeSummaryContent = () => {
        const { selectedPaymentMethodType } = this.state;
        const { paymentLink } = this.props;

        if (selectedPaymentMethodType === 'creditCard') {
            const {
                cardBrand,
                cardSurchargeCalculating,
                cardSurchargeCalculated,
                cardSurchargeValue,
                cardSurchargeCalculationErrorMessage
            } = this.state;

            if (cardSurchargeCalculated)
                return <>
                    <p className="has-text-centered">
                        { cardSurchargeValue && <>
                            <span>A credit card surcharge of </span>
                            <span className="money">{this.getFormatttedCurrency(cardSurchargeValue)}</span>
                            <span> applies.</span>
                        </>
                        }
                        { cardSurchargeValue === 0 &&
                            <span>No credit card surcharge will be charged for this payment.</span>
                        }
                    </p>
                    <p className="has-text-centered">
                        <span>Your </span>
                        <span>{cardBrand || 'card'}</span>
                        <span> will be charged </span>
                        <span className="money">{this.getFormatttedCurrency(paymentLink.amount + (cardSurchargeValue || 0))} </span> 
                        <span>today.</span>
                    </p>
                </>
           
            if (cardSurchargeCalculating)
                return <p className="has-text-centered">
                    <span>Calculating credit card surcharge </span>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                </p>
            
            if (cardSurchargeCalculationErrorMessage)
                return <p className="has-text-centered has-text-danger">{cardSurchargeCalculationErrorMessage}</p>

            return <p className="has-text-centered">
                <span>A credit card surcharge may apply.</span>
            </p>
        }

        if (selectedPaymentMethodType === 'paypal') {
            return <>
                <p className="has-text-centered">
                    <span>A PayPal transaction fee of </span>
                    <span className="money">{this.getFormatttedCurrency(paymentLink.payPalSurcharge)} </span>
                    <span>applies.</span>
                </p>
                <p className="has-text-centered">
                    <span>Your PayPal account will be charged </span>
                    <span className="money">{this.getFormatttedCurrency(paymentLink.amount + paymentLink.payPalSurcharge)} </span> 
                    <span>today.</span>
                </p>
            </>
        }

        return null;
    }

    render() {
        const { paymentLink } = this.props;
        const { 
            checkout,
            selectedPaymentMethodType,
            card,
            cardSurchargeCalculating,
            isProcessing
        } = this.state;

        if (!checkout) return null;

        if (!paymentLink.isCardHolder)
            return (
                <div className="payment-link-details">
                    <p className="has-text-centered">
                        This payment link is valid for the card holder only.
                    </p>
                </div>
            );

        return (
            <div className="payment-link-details">
                <p className="has-text-centered">
                    Thank you for your interest with booking number <span className="reference-no">{paymentLink.referenceNo}</span>.
                </p>
                <p className="has-text-centered">
                    <span>Please complete the below form to authorise payment for your booking for the amount of </span>
                    <span className="money">{this.getFormatttedCurrency(paymentLink.amount)}</span>
                    <span>.</span>
                </p>
                <p className="has-text-centered">
                    You will receive notification on payment confirmation from one of our team members soon.
                </p>
                <PaymentMethodSelector
                    checkout={checkout}
                    onPaymentMethodSelected={this.onPaymentMethodSelected}                    
                    bPayBillerCode={paymentLink.isBpayAllowed ? paymentLink.bookingSystemBPayBillerCode : undefined}
                    bPayCrn={paymentLink.isBpayAllowed ? paymentLink.bookingSystemBPayCRN : undefined}>
                </PaymentMethodSelector>
                {this.surchargeSummaryContent()}
                {selectedPaymentMethodType &&
                    <div className="level payment-panel">
                        <div className="level-left">
                            <div className="level-item">
                                <p>
                                    <span>By clicking </span>
                                    {selectedPaymentMethodType === 'creditCard' && 
                                        <span>"Pay Now"</span>
                                    }
                                    {selectedPaymentMethodType === 'paypal' && 
                                        <span>"PayPal"</span>
                                    }
                                    <span> I agree to the </span>
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        href={this.props.paymentLink.brandTermsAndConditionsUrl || 'https://www.ignitetravel.com/terms-conditions'}>
                                        terms and conditions
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                { selectedPaymentMethodType === 'creditCard' &&
                                    <button
                                        className={`button primary-btn is-medium`}
                                        disabled={!card || cardSurchargeCalculating || isProcessing}
                                        onClick={this.makeCardPayment}>
                                        {isProcessing
                                            ? <span>Processing... <span><i className="fa fa-circle-o-notch fa-spin"></i></span></span>
                                            : <span>Pay Now</span>}
                                    </button>
                                }
                                { selectedPaymentMethodType === 'paypal' &&
                                    <PayPalButtons
                                        checkout={checkout}>
                                    </PayPalButtons>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default PaymentLinkDetails;
